import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { commonApi } from "./commonApi";
import auth from "./reducers/auth";
import snackbar from "./reducers/snackbar";

const rootReducer = combineReducers({
  [commonApi.reducerPath]: commonApi.reducer,
  auth,
  snackbar,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(commonApi.middleware),
});

export default store;
