import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setSignOut } from "./reducers/auth";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: (headers, { getState }) => {
    const accessToken = getState().auth.token;
    if (accessToken) {
      headers.set(process.env.REACT_APP_AUTH_HEADER, accessToken);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let response = await baseQuery(args, api, extraOptions);
  if (response.error && response.error.originalStatus === 401) {
    console.error("SignOut 401");
    api.dispatch(setSignOut());
    if (window) {
      window.location.reload();
    }
  }
  return response;
};

export const commonApi = createApi({
  reducerPath: "commonApi",
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
});
