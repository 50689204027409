import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoggedIn: !!localStorage.getItem(process.env.REACT_APP_AUTH_HEADER),
  token: localStorage.getItem(process.env.REACT_APP_AUTH_HEADER),
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setSignIn: (state, action) => {
      const token = action.payload;
      localStorage.setItem(process.env.REACT_APP_AUTH_HEADER, token);
      state.isLoggedIn = true;
      state.token = token;
    },
    setSignOut: (state) => {
      localStorage.removeItem(process.env.REACT_APP_AUTH_HEADER);
      state.isLoggedIn = false;
      state.token = null;
    },
  },
});

export const { setSignIn, setSignOut } = authSlice.actions;

export default authSlice.reducer;
