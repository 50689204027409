import { Box, CircularProgress } from "@mui/material";

function LazySpinner() {
  return (
    <Box sx={{ width: "100%", textAlign: "center" }}>
      <CircularProgress />
    </Box>
  );
}

export default LazySpinner;
