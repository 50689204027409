import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isShow: false,
  type: "info",
  message: "",
};

export const snackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    toggleSnackbar: (state, action) => {
      state.isShow = action.payload.isShow;
      state.type = action.payload.type || "info";
      state.message = action.payload.message || "";
    },
  },
});

export const { toggleSnackbar } = snackbarSlice.actions;

export default snackbarSlice.reducer;
