import React, { lazy, Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Box, ThemeProvider } from "@mui/material";
import theme from "./theme";
import LazySpinner from "./components/LazySpinner";
const Landing = lazy(() => import("./components/Landing"));
const SubmitProfile = lazy(() => import("./components/SubmitProfile"));
const PrivateLayout = lazy(() => import("./components/PrivateLayout"));
const CardPreviewLayout = lazy(() => import("./components/CardPreviewLayout"));
const SignInForm = lazy(() => import("./components/Auth/SignInForm"));
const SignTemplate = lazy(() => import("./components/Auth/SignTemplate"));
const SignUpForm = lazy(() => import("./components/Auth/SignUpForm"));
const MainTemplate = lazy(() => import("./components/Main/MainTemplate"));
const HomeSection = lazy(() => import("./components/Main/HomeSection"));
const ProfileSection = lazy(() => import("./components/Main/ProfileSection"));
const PriceListSection = lazy(() => import("./components/Main/PriceListSection"));

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Suspense
        fallback={
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
            }}
          >
            <LazySpinner />
          </Box>
        }
      >
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/submit-profile/:token" element={<SubmitProfile />} />
          <Route path="/preview/:previewLink" element={<CardPreviewLayout />} />
          <Route element={<SignTemplate />}>
            <Route path="/sign-in" element={<SignInForm />} />
            <Route path="/sign-up" element={<SignUpForm />} />
          </Route>
          <Route element={<PrivateLayout />}>
            <Route path="/company" element={<MainTemplate />}>
              <Route index element={<HomeSection />} />
              <Route path="settings" element={<ProfileSection />} />
              <Route path="price-list" element={<PriceListSection />} />
            </Route>
          </Route>
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Suspense>
    </ThemeProvider>
  );
}

export default App;
